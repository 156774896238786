<template>
  <vue-final-modal
    v-model="showRefund"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="modal-close" @click="toggle">
      <i class="fas fa-times"/>
    </div>
    <div class="modal-title">{{$t('global.refund')}} {{transid}}</div>
    <form class="modal-page" @submit.prevent="onSubmit">
      <h3 class="mg-t-20 mg-b-30 title-gray center-text">Total: {{$filters.formatPrice(max)}}</h3>
<!--      <div class="title-gray mg-b-5">{{$t('global.refund_amount')}}</div>-->
<!--      <div class="form-element mg-b-20">-->
<!--        <input type="number" :max="max" required v-model="refundPrice" class="form-control"/>-->
<!--      </div>-->
      <div class="title-gray mg-b-5">{{$t('global.refund_reason')}}</div>
      <div class="form-element">
        <textarea v-model="refundReason" required class="form-control"/>
      </div>
      <div class="flex align-center justify-center mg-t-20">
        <button type="submit" class="btn btn--red w-150">{{$t('global.refund')}}</button>
      </div>
    </form>
    <Loading v-if="isLoading"/>
  </vue-final-modal>
</template>

<script>
import Loading from '@/components/loading/Loading'
import { Orders } from '@/api/api'
export default {
  name: 'Refund',
  components: { Loading },
  props: [
    'transid',
    'refundId',
    'max'
  ],
  data () {
    return {
      showRefund: false,
      refundPrice: 0,
      isLoading: false,
      refundReason: ''
    }
  },
  created () {
  },
  methods: {
    async onSubmit () {
      this.isLoading = true

      try {
        await Orders.refund(this.refundId, this.refundPrice, this.refundReason)
        this.$emit('onRefund', { refund: this.refundPrice, reason: this.refundReason })
      } catch (err) {
        this.$toast.error(this.$t('global.refund_fail'))
      } finally {
        this.isLoading = false
      }
    },
    toggle () {
      this.refundPrice = 0
      this.refundReason = ''
      this.showRefund = !this.showRefund
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-page {
    width: 300px !important;
  }
</style>
